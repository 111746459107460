import { loader } from "graphql.macro";

export function getVariables(query: string): string {
  return JSON.stringify(
    Object.keys(variables)
      .filter((key) => query.includes(`$${key}`))
      .reduce(
        (acc: { [key: string]: string }, key: string) => ({
          ...acc,
          [key]: variables[key],
        }),
        {},
      ),
  );
}
export const queries: { name: string; query: any }[] = [
  {
    name: "vehicleListAll",
    query: loader("../examples/vehicle/vehicleListAll.graphql"),
  },
  {
    name: "vehicleListFilter",
    query: loader("../examples/vehicle/vehicleListFilter.graphql"),
  },
  {
    name: "vehicle",
    query: loader("../examples/vehicle/vehicle.graphql"),
  },
  {
    name: "stationAround",
    query: loader("../examples/station/stationAround.graphql"),
  },
  {
    name: "station",
    query: loader("../examples/station/station.graphql"),
  },
  {
    name: "reviewList",
    query: loader("../examples/station/reviewList.graphql"),
  },
  {
    name: "addReview",
    query: loader("../examples/station/addReview.graphql"),
  },
  {
    name: "amenityList",
    query: loader("../examples/station/amenityList.graphql"),
  },
  {
    name: "operatorList",
    query: loader("../examples/operator/operatorList.graphql"),
  },
  {
    name: "operatorListFilter",
    query: loader("../examples/operator/operatorListFilter.graphql"),
  },
  {
    name: "[NEW] createRoute",
    query: loader("../examples/route-v2/createRoute.graphql"),
  },
  {
    name: "[NEW] getRoute",
    query: loader("../examples/route-v2/getRoute.graphql"),
  },
  {
    name: "[NEW] route",
    query: loader("../examples/route-v2/route.graphql"),
  },
  {
    name: "newRoute",
    query: loader("../examples/route/newRoute.graphql"),
  },
  {
    name: "route",
    query: loader("../examples/route/route.graphql"),
  },
  {
    name: "routeUpdatedById",
    query: loader("../examples/route/routeUpdatedById.graphql"),
  },
  {
    name: "[NEW] getRouteEmissions",
    query: loader("../examples/route-v2/getRouteEmissions.graphql"),
  },
  {
    name: "routeEmissions",
    query: loader("../examples/emissions/routeEmissions.graphql"),
  },
  {
    name: "startNavigation",
    query: loader("../examples/navigation/startNavigation.graphql"),
  },
  {
    name: "navigation",
    query: loader("../examples/navigation/navigation.graphql"),
  },
  {
    name: "navigationUpdatedById",
    query: loader("../examples/navigation/navigationUpdatedById.graphql"),
  },
  {
    name: "updateNavigation",
    query: loader("../examples/navigation/updateNavigation.graphql"),
  },
  {
    name: "recalculateNavigation",
    query: loader("../examples/navigation/recalculateNavigation.graphql"),
  },
  {
    name: "finishNavigation",
    query: loader("../examples/navigation/finishNavigation.graphql"),
  },
];
export const variables: { [key: string]: string } = {
  vehicleId: "5f043d91bc262f1627fc032f",
  stationId: "5ed11ca3b899ae5a37983932",
};

export function getTabs(headers: {
  "x-client-id": string;
  "x-app-id"?: string;
}) {
  return queries.map((query) => ({
    endpoint: process.env.REACT_APP_API_ENDPOINT,
    query: query.query.loc.source.body,

    name: query.name,
    variables: getVariables(query.query.loc.source.body),
    headers,
  }));
}

export function getQueryIndex(queryName: string): number {
  return queryName
    ? queries.findIndex(
        (query) => query.name.toLowerCase() === queryName.toLowerCase(),
      )
    : -1;
}
