import { store } from 'graphql-playground-react';
import React, { FunctionComponent } from 'react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PlaygroundWrapper } from './components';

interface IProps {}

interface Props extends IProps {}

ReactGA.initialize('UA-82148343-10');
ReactGA.pageview(window.location.pathname + window.location.search);

const App: FunctionComponent<IProps> = function(_props: Props) {
  return (
    <div className="App">
      <Router>
        <Provider store={store}>
          <PlaygroundWrapper />
        </Provider>
      </Router>
    </div>
  );
};

export default App;
